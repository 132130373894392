export const SET_LOADING = 'SET_LOADING';

// Registration and Login
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

// Artists
export const GET_ARTISTS_SUCCESS = 'GET_ARTISTS_SUCCESS';
export const GET_ARTISTS_FAILURE = 'GET_ARTISTS_FAILURE';
export const GET_ARTISTS_BY_IDS_SUCCESS = 'GET_ARTISTS_BY_IDS_SUCCESS';
export const GET_ARTISTS_BY_IDS_FAILURE = 'GET_ARTISTS_BY_IDS_FAILURE';
export const GET_ARTIST_SUCCESS = 'GET_ARTIST_SUCCESS';
export const GET_ARTIST_FAILURE = 'GET_ARTIST_FAILURE';
export const ADD_ARTIST_SUCCESS = 'ADD_ARTIST_SUCCESS';
export const ADD_ARTIST_FAILURE = 'ADD_ARTIST_FAILURE';
export const DELETE_ARTIST_SUCCESS = 'DELETE_ARTIST_SUCCESS';
export const DELETE_ARTIST_FAILURE = 'DELETE_ARTIST_FAILURE';
export const UPDATE_ARTIST_SUCCESS = 'UPDATE_ARTIST_SUCCESS';
export const UPDATE_ARTIST_FAILURE = 'UPDATE_ARTIST_FAILURE';

// Editions
export const ADD_EDITION_SUCCESS = 'ADD_EDITION_SUCCESS';
export const ADD_EDITION_FAILURE = 'ADD_EDITION_FAILURE';
export const GET_EDITION_SUCCESS = 'GET_EDITION_SUCCESS';
export const GET_EDITION_FAILURE = 'GET_EDITION_FAILURE';
export const GET_EDITIONS_SUCCESS = 'GET_EDITIONS_SUCCESS';
export const GET_EDITIONS_FAILURE = 'GET_EDITIONS_FAILURE';
export const GET_EDITIONS_BY_ARTIST_FAILURE = 'GET_EDITIONS_BY_ARTIST_FAILURE';
export const GET_EDITIONS_BY_ARTIST_SUCCESS = 'GET_EDITIONS_BY_ARTIST_SUCCESS';
export const UPDATE_EDITION_SUCCESS = 'UPDATE_EDITION_SUCCESS';
export const UPDATE_EDITION_FAILURE = 'UPDATE_EDITION_FAILURE';

// NewsPosts
export const GET_NEWSPOST_SUCCESS = 'GET_NEWSPOST_SUCCESS';
export const GET_NEWSPOST_FAILURE = 'GET_NEWSPOST_FAILURE';
export const GET_NEWSPOSTS_SUCCESS = 'GET_NEWSPOSTS_SUCCESS';
export const GET_NEWSPOSTS_FAILURE = 'GET_NEWSPOSTS_FAILURE';
export const ADD_NEWSPOST_SUCCESS = 'ADD_NEWSPOST_SUCCESS';
export const ADD_NEWSPOST_FAILURE = 'ADD_NEWSPOST_FAILURE';
